class Template {
    constructor() {
        this.template = document.querySelector(`#${this.getTemplate()}`);
    }

    /**
     * OVERRIDE
     * Return the id of the template without a leading #.
     * This is the progress indicator that should be used.
     * @returns string|null
     */
    getTemplate() {
        return null;
    }

    /**
     * Returns a clone of the provided template
     * for editing and inserting into the page.
     * @returns {Node} The cloned template.
     */
    cloneTemplate() {
        return this.template.content.cloneNode(true);
    }
}